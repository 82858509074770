@import url("https://fonts.googleapis.com/css2?family=Alegreya&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@600;800&display=swap');

@import "./assets/library";


body {
  margin: unset;
}
.icon-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  svg {
    max-height: 100%;
    max-width: 100%;
    .curtain {
    }
    .svg-type-font {
    }

    .hide-type-font {
      display: none;
    }
  }
}
