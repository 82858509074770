/*$mango: #fda061;
$orange: #fc6057;
$babyblue: #1bc1a1;
$slate: #2c3043;

$purple: #cc1233;
$purple-dark: #960f27;*/


$gris-fonce: rgb(94, 96, 97);
$rouge-carmin: rgb(143, 58, 67);
$rose-clair: rgb(214, 175, 166);
$bleu-outremer-31: rgb(77, 106, 168);
$bleu-cerelueen-31: rgb(62, 110, 144);
$blanc: rgb(234, 219, 192);
$gris-31: rgb(146, 148, 148);
$gris-clair-31: rgb(188, 187, 182);
$gris-fonce: rgb(94, 96, 97);
$rouge-vermillont: rgb(155, 55, 56);
$rouge-rubia: rgb(148, 58, 77);
$vert-31: rgb(127, 162, 90);
$vert-clair: rgb(171, 193, 122);
$vert-fonce: rgb(62, 111, 66);
$vert-anglais: rgb(64, 110, 88);
$vert-anglais-claire: rgb(145, 175, 161);
$vert-anglais-pale: rgb(190, 203, 183);
$vert-jaune-clair: rgb(196, 211, 155);
$ocre-rouge-moyen: rgb(205, 152, 134);
$ocre-rouge-claire: rgb(219, 190, 170);
$ocre-rouge: rgb(139, 77, 62);
$terre-ombre-brule: rgb(76, 66, 61);
$terre-sienne-claire: rgb(216, 178, 154);
$terre-sienne-claire-31: rgb(219, 190, 170);
$terre-sienne-brique: rgb(182, 123, 102);
$terre-sienne-brule: rgb(104, 68, 60);
$terre-sienne-31: rgb(261, 178, 154);
$ombre-naturelle-clair: rgb(90, 85, 80);
$ombre-brule-clair: rgb(183, 163, 146);
$orange-clair: rgb(220, 141, 103);

$orange-pale: rgb(234, 207, 185);
$ceruleen-moyen: rgb(138, 181, 186);
$rose-pale: rgb(230, 205, 191);
$bleu-ceruleen-pale: rgb(198, 213, 204);
$ceruleen-clair: rgb(168, 196, 193);
$outremer-pale: rgb(182, 198, 206);
$outremer-moyen: rgb(143, 171, 201);
$outremer-clair: rgb(171, 189, 200);

$svg-text-173438: $ombre-naturelle-clair;
$svg-text-4CAB27: $vert-anglais-pale;
$svg-text-D388C1: $ocre-rouge-claire;
$svg-text-AC002E: $ocre-rouge-moyen;
$svg-text-F31C80: $vert-anglais-pale;
$svg-text-5A9C36: $terre-sienne-brique;
$svg-text-FE230B: $vert-anglais-pale;
$svg-text-C12D93: $ocre-rouge-moyen;
$svg-text-42238C: $ombre-brule-clair;
$svg-text-52AE26: $terre-sienne-claire-31;
$svg-text-3E97A9: $terre-sienne-brule;
$svg-text-2A3796: $orange-pale;
$svg-text-50B14F: $vert-jaune-clair;


$logo-bg: $ceruleen-moyen; // $terre-sienne-claire;
$header-bg: $bleu-cerelueen-31;

$spielgruppe-bg: $vert-anglais-claire;

$figurentheater-icon-bg: $ceruleen-moyen;
$figurentheater-icon: $rouge-rubia;
$figurentheater-icon-stroke: $rouge-rubia;

$schlosswochen-icon-bg: $rouge-rubia;
$schlosswochen-icon: $ceruleen-moyen;
$schlosswochen-icon-stroke: $ceruleen-moyen;

$spielgruppe-icon-bg: $ceruleen-moyen;
$spielgruppe-icon: $rouge-rubia;
$spielgruppe-icon-stroke: $rouge-rubia;
